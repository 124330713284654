import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { KnowledgeChunk } from 'apps/aiAlwaysOnAutomations/constants/types'
import { alert } from 'common/core'
import { createAsyncAction } from 'shared/lib/redux'

const mockedChunks: KnowledgeChunk[] = [
  {
    type: 'text',
    content: `Instagram: @manychat Category: Software Website: manychat.partnerlinks.io/hello`,
  },
  {
    type: 'text',
    content: `Instagram Description: The OG’s at “comment WORD for the link” ✨ Automation by creators, for creators 💙 Official Meta Business Partners 👇 DM us 𝘿𝙀𝙈𝙊 to see how it works`,
  },
  {
    type: 'text',
    content:
      'Manychat is a popular AI-powered chatbot platform designed for automating conversations on Instagram, Facebook Messenger, WhatsApp, and SMS. It helps businesses engage with customers, generate leads, and provide instant support using automated flows, smart replies, and integrations with tools like Shopify, HubSpot, and Zapier. With Manychat, users can create no-code chatbots, send broadcasts, and manage customer interactions efficiently. It’s widely used for marketing, sales, and customer service, making communication seamless and scalable.',
  },
]

const NAMESPACE = 'aiAlwaysOnAutomations'

export interface AiAlwaysOnAutomationsState {
  businessContext: KnowledgeChunk[]
  scenario: {
    faq: {
      published: boolean
    }
  }
  selectedKnowledgeChunkIndex: number | null
}

export const AiAlwaysOnAutomationsInitialState: AiAlwaysOnAutomationsState = {
  businessContext: mockedChunks,
  scenario: {
    faq: {
      published: true,
    },
  },
  selectedKnowledgeChunkIndex: null,
}

const { reducer, actions } = createSlice({
  initialState: AiAlwaysOnAutomationsInitialState,
  name: NAMESPACE,
  reducers: {
    // setData: (state, action: PayloadAction<any>) => {
    //   state.businessContext = action.payload.business_context
    //   state.scenario.faq.published = action.payload.scenario.faq.published
    // },
    addChunk: (state, action: PayloadAction<KnowledgeChunk>) => {
      state.businessContext.push(action.payload)
    },
    removeChunk: (state, action: PayloadAction<number>) => {
      console.log('action.payload', action.payload)
      state.businessContext.splice(action.payload, 1)
    },
    updateChunk: (
      state,
      action: PayloadAction<{ index: number; data: Partial<KnowledgeChunk> }>,
    ) => {
      const { index, data } = action.payload
      const chunk = state.businessContext[index]
      if (chunk) {
        state.businessContext[index] = { ...chunk, ...data }
      }
    },
    setSelectedKnowledgeChunkIndex: (state, action: PayloadAction<number | null>) => {
      state.selectedKnowledgeChunkIndex = action.payload
    },
    setFaqScenarioStatus: (state, action: PayloadAction<boolean>) => {
      state.scenario.faq.published = action.payload
    },
  },
})

export const AiAlwaysOnAutomationsReducer = reducer

const getState = (state: RootState) => state.aiAlwaysOnAutomations

export const AiAlwaysOnAutomationsSelectors = {
  getState,
  getBusinessContext: (state: RootState) => getState(state).businessContext,
  getSelectedKnowledgeChunkIndex: (state: RootState) => getState(state).selectedKnowledgeChunkIndex,
  getSelectedKnowledgeChunk: (state: RootState) => {
    const index = getState(state).selectedKnowledgeChunkIndex
    if (index === null) {
      return undefined
    }
    return getState(state).businessContext[index]
  },
  getFaqScenarioStatus: (state: RootState) => getState(state).scenario.faq.published,
}

const saveKnowledgeChunk = createAsyncAction(
  `${NAMESPACE}/saveKnowledgeChunk`,
  async (data: KnowledgeChunk, { dispatch, getState }) => {
    const index = AiAlwaysOnAutomationsSelectors.getSelectedKnowledgeChunkIndex(getState())
    if (index) {
      dispatch(actions.updateChunk({ index, data }))
      alert('Chunk was been edited', 'success')
    } else {
      dispatch(actions.addChunk(data))
      alert('Chunk was been added', 'success')
    }

    // dispatch(actions.openKnowledgeList())
    // async logic to send all the data to backend side
  },
)

export const AiAlwaysOnAutomationsActions = {
  addChunk: actions.addChunk,
  removeChunk: actions.removeChunk,
  updateChunk: actions.updateChunk,
  setSelectedKnowledgeChunkIndex: actions.setSelectedKnowledgeChunkIndex,
  saveKnowledgeChunk,
  setFaqScenarioStatus: actions.setFaqScenarioStatus,
}
