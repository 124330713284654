export const UI_ADD_NOTIFICATION = 'UI_ADD_NOTIFICATION'
export const UI_REMOVE_NOTIFICATION = 'UI_REMOVE_NOTIFICATION'
export const UI_DELETE_NOTIFICATION = 'UI_DELETE_NOTIFICATION'

export const UI_OPEN_CONNECT_CHANNEL_MODAL = 'UI_OPEN_CONNECT_CHANNEL_MODAL'
export const UI_CLOSE_CONNECT_CHANNEL_MODAL = 'UI_CLOSE_CONNECT_CHANNEL_MODAL'

export const UI_SHOW_IG_CHANGE_ACCOUNT_ERROR_MODAL = 'UI_SHOW_IG_CHANGE_ACCOUNT_ERROR_MODAL'
export const UI_CLOSE_IG_CHANGE_ACCOUNT_ERROR_MODAL = 'UI_CLOSE_IG_CHANGE_ACCOUNT_ERROR_MODAL'
export const UI_IG_CHANGE_ACCOUNT_ERROR = 'UI_IG_CHANGE_ACCOUNT_ERROR'

export const UI_OPEN_COMPACT_SETTINGS_MODAL = 'UI_OPEN_COMPACT_SETTINGS_MODAL'
export const UI_CLOSE_COMPACT_SETTINGS_MODAL = 'UI_CLOSE_COMPACT_SETTINGS_MODAL'

export const UI_SET_CGT_NOT_ALLOWED_MODAL_STATUS = 'UI_SET_CGT_NOT_ALLOWED_MODAL_STATUS'

export const UI_OPEN_SIDEBAR = 'UI_OPEN_SIDEBAR'
export const UI_CLOSE_SIDEBAR = 'UI_CLOSE_SIDEBAR'

export const UI_TOGGLE_SIDEBAR_FOLDING = 'UI_TOGGLE_SIDEBAR_FOLDING'

export const UI_OPEN_LIGHTBOX = 'UI_OPEN_LIGHTBOX'
export const UI_CLOSE_LIGHTBOX = 'UI_CLOSE_LIGHTBOX'

export const UI_NOTIFY_ADMIN_MIGRATION_SHOW_MODAL = 'UI_NOTIFY_ADMIN_MIGRATION_SHOW_MODAL'
export const UI_NOTIFY_ADMIN_MIGRATION_HIDE_MODAL = 'UI_NOTIFY_ADMIN_MIGRATION_HIDE_MODAL'

export const UI_OPEN_PREVIEW_MODAL = 'UI_OPEN_PREVIEW_MODAL'
export const UI_CLOSE_PREVIEW_MODAL = 'UI_CLOSE_PREVIEW_MODAL'

export const UI_OPEN_FIRST_AUTOMATION_CREATION_MODAL = 'UI_OPEN_FIRST_AUTOMATION_CREATION_MODAL'
export const UI_CLOSE_FIRST_AUTOMATION_CREATION_MODAL = 'UI_CLOSE_FIRST_AUTOMATION_CREATION_MODAL'

export const UI_SET_FIRST_TIKTOK_AUTOMATION_SESSION = 'UI_SET_FIRST_TIKTOK_AUTOMATION_SESSION'
export const UI_UNSET_FIRST_TIKTOK_AUTOMATION_SESSION = 'UI_UNSET_FIRST_TIKTOK_AUTOMATION_SESSION'

export const UI_OPEN_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL =
  'UI_OPEN_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL'
export const UI_CLOSE_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL =
  'UI_CLOSE_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL'

export const UI_SET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK =
  'UI_SET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK'
export const UI_UNSET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK =
  'UI_UNSET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK'

export const UI_OPEN_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP =
  'UI_OPEN_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP'
export const UI_CLOSE_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP =
  'UI_CLOSE_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP'

export const UI_RESET_FIRST_TIKTOK_AUTOMATION = 'UI_RESET_FIRST_TIKTOK_AUTOMATION'

export const UI_SET_HEADER_EDITING = 'UI_SET_HEADER_EDITING'
export const UI_SET_ERROR_HEADER_EDITING_TS = 'UI_SET_ERROR_HEADER_EDITING_TS'

export const UI_OPEN_FLOWBUILDER_HELP_MODAL = 'UI_OPEN_FLOWBUILDER_HELP_MODAL'
export const UI_CLOSE_FLOWBUILDER_HELP_MODAL = 'UI_CLOSE_FLOWBUILDER_HELP_MODAL'

export const UI_OPEN_WA_COEXISTENCE_CONTACT_IMPORT_MODAL =
  'UI_OPEN_WA_COEXISTENCE_CONTACT_IMPORT_MODAL'
export const UI_CLOSE_WA_COEXISTENCE_CONTACT_IMPORT_MODAL =
  'UI_CLOSE_WA_COEXISTENCE_CONTACT_IMPORT_MODAL'

export const UI_OPEN_QUICK_CAMPAIGN_PREVIEW_MODAL = 'UI_OPEN_QUICK_CAMPAIGN_PREVIEW_MODAL'
export const UI_CLOSE_QUICK_CAMPAIGN_PREVIEW_MODAL = 'UI_CLOSE_QUICK_CAMPAIGN_PREVIEW_MODAL'
