import { api } from 'shared/api'
import { QuickCampaignApiSchemas } from 'shared/api/requests/quickCampaign/schemas'

export const QuickCampaignApi = {
  fetch: api.account.createGet({
    url: '/quickCampaign/list',
    schemas: QuickCampaignApiSchemas.fetch,
  }),
  fetchItem: api.account.createPost({
    url: '/quickCampaign/get',
    schemas: QuickCampaignApiSchemas.get,
  }),
  setData: api.account.createPost({
    url: '/quickCampaign/put',
    schemas: QuickCampaignApiSchemas.put,
  }),
  getFlow: api.account.createGet({
    url: '/quickCampaign/getFlow',
    schemas: QuickCampaignApiSchemas.getFlow,
  }),
  getByParentId: api.account.createGet({
    url: '/quickCampaign/getByParentId',
    schemas: QuickCampaignApiSchemas.getByParentId,
  }),
}
